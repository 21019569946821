/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        console.log("%cBuilt by %c "+"Your Mom's Favourite"+" %c "+ "https://www.mustard.rocks","padding:8px 5px; color:"+"black"+"; line-height:25px;","padding:8px 15px; color:"+"black"+"; background-color:"+"#ffea55"+"; line-height:25px;","padding:8px 5px; color:"+"black"+"; line-height:25px;");
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

         // Change Bar
         $(function() {
          var header = $(".navbar");
          $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 100) {
              header.removeClass('clear-nav').addClass("solid-nav");
            } else {
              header.removeClass("solid-nav").addClass('clear-nav');
            }
          });
        });

         function sticktothetop() {
          var window_top = $(window).scrollTop();
          var top = $('#stick-here').offset().top - 63;
          if (window_top >= top) {
            $('.subnav').addClass('stick');
          } else {
            $('.subnav').removeClass('stick');
          }
        }

        if ($(".subnav").length > 0) {
          $(function() {
            $(window).scroll(sticktothetop);
            sticktothetop();
          });
        }

        var swiper = new Swiper('.swiper-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        var swiper = new Swiper('.swiper-team', {
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        // Vertical Align Hack
        // Main Wrapper class of va-align-content
        // Give parent wrapper class va-parent and element wrapper class va-child 
        $(".va-align-content").each(function(){ 
          var containerHeight = $('.va-parent', this).height();
          var contentHeight = $('.va-child', this).height();
          var calcValue = (containerHeight - contentHeight);
          var paddingSet = parseInt(calcValue / 2);
          $('.va-child', this).css({'padding-top': paddingSet + 'px'});
        });


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
